import React from 'react';
import './CoverDisplay.css'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'lightbox.js-react/dist/index.css'
import {SlideshowLightbox} from 'lightbox.js-react'

const ImageDisplay = ({ image }) => {
  return (
    <Container>
      <Row className='imageBorder'>
        <Col>
          <SlideshowLightbox 
              className='container grid grid-cols-3 gap-2 mx-auto' 
              showThumbnails={false}
              showThumbnailIcon={false}
              showSlideshowIcon={false}
              showFullScreenIcon={false}
              showMagnificationIcons={true}
              imgAnimation="fade"
              modalClose="clickOutside">
              <img className='cover-image-display shadow' src={image.name} alt={image.usrName}/>
            </SlideshowLightbox> 
        </Col>
      </Row>
    </Container>
  );
};

export default ImageDisplay;