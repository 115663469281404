import React from 'react';
import './CoverDisplay.css'
//import Logo from '../../assets/icons8-money-50.png'

const CardSearchImage = ({ image }) => {
  let imageCoverData;

  try {
    imageCoverData = JSON.parse(image.cover_image);
  } catch(e) {
    // If parsing fails, we return an empty imageCoverData
    imageCoverData = [];
  }

  const modifiedCoverImageData = imageCoverData.map((image) => {
    const modifiedCoverImage = { ...image };
      if (modifiedCoverImage.name.includes("/home/u825454141/domains/alientrader.net/public_html/")) {
        modifiedCoverImage.name = modifiedCoverImage.name.replace(
          "/home/u825454141/domains/alientrader.net/public_html/",
          "http://alientrader.net/"
        );
      } else {
        modifiedCoverImage.name = "https://api.alientrader.cards/" + modifiedCoverImage.name;
      }
      if (modifiedCoverImage.thumbnail) {
        if (modifiedCoverImage.thumbnail.includes("/home/u825454141/domains/alientrader.net/public_html/")) {
          modifiedCoverImage.thumbnail = modifiedCoverImage.thumbnail.replace(
            "/home/u825454141/domains/alientrader.net/public_html/",
            "http://alientrader.net/"
          );
        } else {
          modifiedCoverImage.thumbnail = "https://api.alientrader.cards/" + modifiedCoverImage.thumbnail;
        }
      }
    return modifiedCoverImage;
  });

  

  return (
    <div>
      {modifiedCoverImageData.map((img, index) => (
        <a key={index} href={`/card/${image.card_id}`} data-lightbox="cover" data-title={img.usrName}>
          <img src={img.thumbnail} alt={img.usrName} className='imagesPageDisplay' />
        </a>
      ))}
      <div className='subCardWallGrades'>
        {image.card_grade_number}
      </div>
      <div className='subCardWallLogo'>
        {/* {image.card_owner === 'Shawn L' && (
          <img
            alt=""
            src={Logo}
            width="20"
            height="20"
            className="d-inline-block align-center"
          />
        )} */}
      </div>
    </div>
  );
};

export default CardSearchImage;