import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import './submission.css'
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';

function SubAdd() {
    const { id } = useParams();
    const navigate = useNavigate();
    const goBack = () => navigate(-1);
    const [subID, setSubID] = useState([]);
    const [subGrader, setSubGrader] = useState('')
    const [subOrderNumber, setSubOrderNumber] = useState('')
    const [subDate, setSubDate] = useState('')
    const [subReturnDate, setSubReturnDate] = useState('')
    const [subPreCost, setSubPreCost] = useState('')
    const [subUpcharge, setSubUpcharge] = useState('')
    const [subTotalCards, setSubTotalCards] = useState('')
    const [subShippingTo, setSubShippingTo] = useState('')
    const [subShippingFrom, setSubShippingFrom] = useState('')
    const [subNotes, setSubNotes] = useState('')
    const [subPSAorderArrived, setSubPSAorderArrived] = useState('')
    const [subPSAorderPrep, setSubPSAorderPrep] = useState('')
    const [subPSAresearchID, setSubPSAresearchID] = useState('')
    const [subPSAgrading, setSubPSAgrading] = useState('')
    const [subPSAassembly, setSubPSAassembly] = useState('')
    const [subPSAgradesReady, setSubPSAgradesReady] = useState('')
    const [subPSAqaChecks, setSubPSAqaChecks] = useState('')
    const [subPSAcompleted, setSubPSAcompleted] = useState('')

    useEffect(() => {
        axios.get(`https://api.alientrader.cards/submission/${id}`)
          .then((response) => {
            setSubID(response.data);
            setSubGrader(response.data[0].sub_grader);
            setSubOrderNumber(response.data[0].sub_order_number);
            if (response.data[0].sub_date) {
              setSubDate(convertISOToDateInputValue(response.data[0].sub_date));
            }
            if (response.data[0].sub_return_date) {
              setSubReturnDate(convertISOToDateInputValue(response.data[0].sub_return_date));
            }
            setSubTotalCards(response.data[0].sub_total_cards);
            setSubPreCost(response.data[0].sub_precost);
            setSubUpcharge(response.data[0].sub_upcharge);
            setSubShippingTo(response.data[0].sub_shipping_to);
            setSubShippingFrom(response.data[0].sub_shipping_from);
            setSubNotes(response.data[0].sub_notes);
            if (response.data[0].PSA_Order_Arrived) {setSubPSAorderArrived(convertISOToDateInputValue(response.data[0].PSA_Order_Arrived))}
            if (response.data[0].PSA_Order_Prep) {setSubPSAorderPrep(convertISOToDateInputValue(response.data[0].PSA_Order_Prep))}
            if (response.data[0].PSA_Research_ID) {setSubPSAresearchID(convertISOToDateInputValue(response.data[0].PSA_Research_ID))}
            if (response.data[0].PSA_Grading) {setSubPSAgrading(convertISOToDateInputValue(response.data[0].PSA_Grading))}
            if (response.data[0].PSA_Assembly) {setSubPSAassembly(convertISOToDateInputValue(response.data[0].PSA_Assembly))}
            if (response.data[0].PSA_Grades_Ready) {setSubPSAgradesReady(convertISOToDateInputValue(response.data[0].PSA_Grades_Ready))}
            if (response.data[0].PSA_QA_Checks) {setSubPSAqaChecks(convertISOToDateInputValue(response.data[0].PSA_QA_Checks))}
            if (response.data[0].PSA_Completed) {setSubPSAcompleted(convertISOToDateInputValue(response.data[0].PSA_Completed))}
          })
          .catch((error) => {
            console.error('Error fetching card data:', error);
          });
      }, [id]);

    const subData = subID[0];

    const handleSave = async () => {
        const updatedSubData = {
            sub_grader: subGrader,
            sub_order_number: subOrderNumber,
            sub_date: subDate,
            sub_return_date: subReturnDate,
            sub_precost: subPreCost,
            sub_upcharge: subUpcharge,
            sub_total_cards: subTotalCards,
            sub_shipping_to: subShippingTo,
            sub_shipping_from: subShippingFrom,
            sub_notes: subNotes,
            PSA_Order_Arrived: subPSAorderArrived,
            PSA_Order_Prep: subPSAorderPrep,
            PSA_Research_ID: subPSAresearchID,
            PSA_Grading: subPSAgrading,
            PSA_Assembly: subPSAassembly,
            PSA_Grades_Ready: subPSAgradesReady,
            PSA_QA_Checks: subPSAqaChecks,
            PSA_Completed: subPSAcompleted
        };
        setSubID((prevData) => ({
            ...prevData,
            ...updatedSubData,
        }));
        axios.post(`https://api.alientrader.cards/submission/edit/${subData.sub_id}`, updatedSubData)
        .then((response) => {
            console.log('Submission information updated successfully:', response.data);
            alert('Submission saved successfully!');
            navigate(-1);
        })
        .catch((error) => {
            console.error('Error updating Submission information:', error);
        });
    }
    function convertISOToDateInputValue(isoString) {
        const date = new Date(isoString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // month is 0-based
        const day = String(date.getDate()).padStart(2, '0');
          
        return `${year}-${month}-${day}`;
    }

  return (
    <div>
        <div className='actions'>
            <div className='subBack'><CancelIcon fontSize="large" onClick={goBack}/></div>
            <div className='sub'><h1>Submission</h1></div>
            <div className='subEdit'><button className='edit-button' onClick={handleSave}><SaveIcon fontSize="large" /></button></div>
        </div>
        <Container>
            <Row className='d-flex align-items-center justify-content-center'>
                <Col sm={8} className='subAddBox shadow-sm'>
                    <Row><div className='subAddHeader'>Submission Info</div></Row>
                    <Row className='d-flex align-items-center justify-content-center'>
                        <Col xs={4} className='custom-info-title'>Grader:</Col>
                        <Col>
                            <div className='custom-input-val'>
                                <input
                                    type="text"
                                    id="sub_grader"
                                    className='edit-input'
                                    value={subGrader}
                                    onChange={(e) => setSubGrader(e.target.value)}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className='d-flex align-items-center justify-content-center'>
                        <Col xs={4} className='custom-info-title'>Order Number:</Col>
                        <Col>
                            <div className='custom-input-val'>
                                <input
                                    type="text"
                                    id="sub_order_number"
                                    className='edit-input'
                                    value={subOrderNumber}
                                    onChange={(e) => setSubOrderNumber(e.target.value)}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className='d-flex align-items-center justify-content-center'>
                        <Col xs={4} className='custom-info-title'>Submission Date:</Col>
                        <Col>
                            <div className='custom-input-val'>
                                <input
                                    type="date"
                                    id="sub_date"
                                    className='edit-input'
                                    value={subDate}
                                    onChange={(e) => setSubDate(e.target.value)}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className='d-flex align-items-center justify-content-center'>
                        <Col xs={4} className='custom-info-title'>Return Date:</Col>
                        <Col>
                            <div className='custom-input-val'>
                                <input
                                    type="date"
                                    id="sub_return_date"
                                    className='edit-input'
                                    value={subReturnDate}
                                    onChange={(e) => setSubReturnDate(e.target.value)}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className='d-flex align-items-center justify-content-center'>
                        <Col xs={4} className='custom-info-title'>Total Cards:</Col>
                        <Col>
                            <div className='custom-input-val'>
                                <input
                                    type="text"
                                    id="sub_total_cards"
                                    className='edit-input'
                                    value={subTotalCards}
                                    onChange={(e) => setSubTotalCards(e.target.value)}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className='d-flex align-items-center justify-content-center'>
                        <Col xs={4} className='custom-info-title'>Precost:</Col>
                        <Col>
                            <div className='custom-input-val'>
                                <input
                                    type="text"
                                    id="sub_precost"
                                    className='edit-input'
                                    value={subPreCost}
                                    onChange={(e) => setSubPreCost(e.target.value)}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className='d-flex align-items-center justify-content-center'>
                        <Col xs={4} className='custom-info-title'>Upcharge:</Col>
                        <Col>
                            <div className='custom-input-val'>
                                <input
                                    type="text"
                                    id="sub_upcharge"
                                    className='edit-input'
                                    value={subUpcharge}
                                    onChange={(e) => setSubUpcharge(e.target.value)}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className='d-flex align-items-center justify-content-center'>
                        <Col xs={4} className='custom-info-title'>Ship To:</Col>
                        <Col>
                            <div className='custom-input-val'>
                                <input
                                    type="text"
                                    id="sub_shipping_to"
                                    className='edit-input'
                                    value={subShippingTo}
                                    onChange={(e) => setSubShippingTo(e.target.value)}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className='d-flex align-items-center justify-content-center'>
                        <Col xs={4} className='custom-info-title'>Ship From:</Col>
                        <Col>
                            <div className='custom-input-val'>
                                <input
                                    type="text"
                                    id="sub_shipping_from"
                                    className='edit-input'
                                    value={subShippingFrom}
                                    onChange={(e) => setSubShippingFrom(e.target.value)}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className='d-flex align-items-center justify-content-center'>
                        <Col xs={4} className='custom-info-title'>Notes:</Col>
                        <Col>
                            <div className='custom-input-val'>
                                <textarea
                                    type="textarea"
                                    id="sub_notes"
                                    className='edit-input'
                                    onChange={(e) => setSubNotes(e.target.value)}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row><br /></Row>
                </Col>
            </Row>
            <Row className='d-flex align-items-center justify-content-center'>
                <Col lg={4} md={5} sm={8} className='subAddBox shadow-sm'>
                    <Row><div className='subAddHeader'>PSA Info</div></Row>
                    <Row className='d-flex align-items-center justify-content-center'>
                        <Col xs={4} className='custom-info-title'>Order Arrived:</Col>
                        <Col>
                            <div className='custom-input-val'>
                                <input
                                    type="date"
                                    id="PSA_Order_Arrived"
                                    className='edit-input'
                                    value={subPSAorderArrived}
                                    onChange={(e) => setSubPSAorderArrived(e.target.value)}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className='d-flex align-items-center justify-content-center'>
                        <Col xs={4} className='custom-info-title'>Order Prep:</Col>
                        <Col>
                            <div className='custom-input-val'>
                                <input
                                    type="date"
                                    id="PSA_Order_Prep"
                                    className='edit-input'
                                    value={subPSAorderPrep}
                                    onChange={(e) => setSubPSAorderPrep(e.target.value)}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className='d-flex align-items-center justify-content-center'>
                        <Col xs={4} className='custom-info-title'>Research & ID:</Col>
                        <Col>
                            <div className='custom-input-val'>
                                <input
                                    type="date"
                                    id="PSA_Research_ID"
                                    className='edit-input'
                                    value={subPSAresearchID}
                                    onChange={(e) => setSubPSAresearchID(e.target.value)}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className='d-flex align-items-center justify-content-center'>
                        <Col xs={4} className='custom-info-title'>Grading:</Col>
                        <Col>
                            <div className='custom-input-val'>
                                <input
                                    type="date"
                                    id="PSA_Grading"
                                    className='edit-input'
                                    value={subPSAgrading}
                                    onChange={(e) => setSubPSAgrading(e.target.value)}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className='d-flex align-items-center justify-content-center'>
                        <Col xs={4} className='custom-info-title'>Assembly:</Col>
                        <Col>
                            <div className='custom-input-val'>
                                <input
                                    type="date"
                                    id="PSA_Assembly"
                                    className='edit-input'
                                    value={subPSAassembly}
                                    onChange={(e) => setSubPSAassembly(e.target.value)}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className='d-flex align-items-center justify-content-center'>
                        <Col xs={4} className='custom-info-title'>Grades Ready:</Col>
                        <Col>
                            <div className='custom-input-val'>
                                <input
                                    type="date"
                                    id="PSA_Grades_Ready"
                                    className='edit-input'
                                    value={subPSAgradesReady}
                                    onChange={(e) => setSubPSAgradesReady(e.target.value)}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className='d-flex align-items-center justify-content-center'>
                        <Col xs={4} className='custom-info-title'>QA Checks:</Col>
                        <Col>
                            <div className='custom-input-val'>
                                <input
                                    type="date"
                                    id="PSA_QA_Checks"
                                    className='edit-input'
                                    value={subPSAqaChecks}
                                    onChange={(e) => setSubPSAqaChecks(e.target.value)}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className='d-flex align-items-center justify-content-center'>
                        <Col xs={4} className='custom-info-title'>Completed:</Col>
                        <Col>
                            <div className='custom-input-val'>
                                <input
                                    type="date"
                                    id="PSA_Completed"
                                    className='edit-input'
                                    value={subPSAcompleted}
                                    onChange={(e) => setSubPSAcompleted(e.target.value)}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row><br /></Row>
                </Col>
            </Row>
        </Container>
        <div className='actions'>
            <div className='subBack'><Link to={goBack}><CancelIcon fontSize="large" /></Link></div>
            <div className='sub'><h1>-</h1></div>
            <div className='subEdit'><button className='edit-button' onClick={handleSave}><SaveIcon fontSize="large" /></button></div>
        </div>
    </div>
  )
}

export default SubAdd